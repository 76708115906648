<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-09-28 19:21:03
-->
<template>
  <div class="greenchain ptn_r">
    <div class="w_auto greenSupply ptn_r">
      <div class="horizontal"></div>
      <div class="horizontal_2 flax">
        <p v-if="$store.state.language === 'cn'">当前位置: 新闻动态</p>
        <p v-else>Location: News</p>
      </div>

      <div class=" about Impact">
        <div>News</div>
        <div  v-if="$store.state.language === 'cn'" >最新动态</div>
      </div>
      <div class="flex">
        <div class="grnin ">
          <div v-for="item in dateY" @click="add(item)" :key="item" :class="['flex grnin_1 ',flagNam == item ? 'green_bg': 'green_color']">
            <p>{{date -item +1}}</p>
          </div>
          <!-- <div @click="add(12)" :class="['flex grnin_1 ',flagNam == 12 ? 'green_bg': 'green_color']">
            <p>2021</p>
          </div>
          <div @click="add(2)" :class="['flex grnin_1 ',flagNam == 2 ? 'green_bg': 'green_color']">
            <p>2020</p>
          </div> -->
         
        </div>
        <div class="TheContainer">
          <div class="wh">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dateFormat from '@/utils/time'

export default {
  data() {
    return {
      date: dateFormat(+new Date(), 'yyyy'),
      flagNam: 1,
    }
  },
  mounted() {
    // this.flagNam = this.$route.meta.flagNam
    // this.add(this.flagNam)
    // console.log(this.flagNam,'快来看老客户');

    this.hub.$on('dayeYYYY',(datey)=> {
      // console.log(datey);
      const yyyy =  this.date - datey +1
      // console.log(yyyy);
      this.flagNam = yyyy
    })
  },
  methods: {
    
    add(val) {
      this.flagNam = val
      // this.tatleName =
      this.$router.push({name: 'Newss', query: {
        dateY: this.date -val +1
      }})
       this.hub.$emit('dateY')
     
    }
  },
  computed: {
    dateY() {
     const yyyy =  this.date - 2016 +1
     return yyyy
    }
  }
}
</script>
<style lang="less" scoped>
.ptn_r {
  position: relative;
}
.flex {
  display: flex;
}
.greenchain {
  min-height: 500px;
  overflow: hidden;
}
.horizontal {
  position: absolute;
  left: -200px;
  width: 540px;
  margin-top: 246px;
  border-top: 1px solid #666;
}
.horizontal_2 {
  position: absolute;
  top: 240px;
  left: 460px;
  width: 40000px;
  border-top: 5px solid #8fa926;
  p {
    position: absolute;
    top: -50px;
    border-left: 4px solid #8fa926;
    margin-left: 2px;
    padding-left: 20px;
    font-size: 18px;
  }
}
.about {
  position: absolute;
  top: 120px;
  left: 40px;
  // width: 240px;
  div:nth-of-type(1) {
    font-size: 35px;
    font-weight: 700;
    color: #8fa926;
  }
  div:nth-of-type(2) {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
  }
}
.grnin {
  padding-top: 320px;
  margin-left: 60px;
  width: 280px;
  .grnin_1 {
      width: 100%;
    // justify-content: center;
    // padding-left: 20px;
    height: 60px;
    line-height: 60px;
    font-size: 20px;
text-align: center !important;

    p {
     width: 280px;
    text-align: center;
    }
  }
}
.green_color {
  color: #ebebec;
    text-align: center !important;
    border-top: 1px solid #ebebec ;
    border-bottom: 1px solid #ebebec ;

}
.green_bg {
      width: 100%;

    text-align: center !important;
    border-top: 2px solid #8fa926 ;
    border-bottom: 2px solid #8fa926 ;
     color: #8fa926;
//   background: #8fa926;

}
.TheContainer {
  padding: 247px 0 0 120px;
  flex: 1;
  .wh {
    width: 100%;
  }
}
</style>